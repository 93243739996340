import './ConnectorTable.css';
import { CircularProgress } from '@mui/material';
import ConnectorMiddleHeader from "../Connectors/ConnectorMiddleHeader";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import React from 'react';
import { RiseLoader } from 'react-spinners';
import Box from 'konnect/Box';
import styled from 'styled-components';

 const StyledDiv = styled(Box)`
    padding:5px;
    font-weight: 500;
    font-size: 13px;
 `;

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "absolute",
  top: "70%",
  left: "50%",
  transform: "translateX(-50%)"
};

const colorMapper = {
  "normal" : "#60c610" ,
  "field" : "#efbc0f",
  "cross" : "#f2a541",
  "crimp" : "#f04b4b"
}

const ConnectorTable = ({ connectorDevices, handleTabChange, selectedTab, faultMapper, loading = false}) => {
  return ( 
    <>
      <div className='col-lg-12 pl-0 pr-4'>
        <div className='row reports-content-row mt-4'>
          {connectorDevices?.length ? (
            <div className='reports-content-box' style={{ textAlign: 'left' }}>
              <table className="table table-hover mobile-none">
                <thead className='table-header-bg sticky-top' style={{zIndex: 0}}>
                  <tr>
                    <th scope="col" className='table-heading'>Connector Name</th>
                    <th scope='col' className='table-heading'>
                      UID
                    </th>
                    <th scope="col" className='table-heading'>Site Address
                    </th>
                    <th scope="col" className='table-heading'>{'Connector Resistance'}</th>
                    
                    <th scope='col' className='table-heading'>
                      Connector Surface Tempterature
                    </th>
                    <th scope='col' className='table-heading'>
                      Connector Fault
                    </th>
                    <th scope='col' className='table-heading'>
                      {selectedTab=== 1 ? "Time to Failure":selectedTab=== 2 ? " Intervention Date": "Recommended Action"}
                    </th>
                  </tr>
                </thead>
                <tbody className='connectors-table-data'>
                  {!loading ? 
                  connectorDevices?.filter(item => faultMapper[item?.current_status] === selectedTab).map((connector) => (
                    <tr
                      style={{ textAlign: 'center' }}
                      key={connector.device_name}
                    >
                      <td>
                      <StyledDiv>
                        <Link
                          to={`/device/${connector?.device_id}/view`}
                          style={{ textDecoration: "none", color: "#0057FF" }}
                        >
                          {connector?.device_name}
                        </Link>
                        </StyledDiv>
                      </td>
                      <td style={{color:"#121212"}}>
                      <StyledDiv>
                        {connector?.device_id}
                        </StyledDiv>
                      </td>
                      <td style={{color:"#121212"}}>
                        <StyledDiv>
                        {connector?.site_address}
                        </StyledDiv>
                      </td>
                      <td style={{color:"#121212"}}>
                      <StyledDiv>
                        {connector?.average_resistance ? Number(connector?.average_resistance).toFixed(2) : 0}
                        </StyledDiv>
                      </td>
                      <td style={{color:"#121212"}}>
                      <StyledDiv>
                        {connector?.average_surface_temperature? `${Number(connector?.average_surface_temperature).toFixed(2)}°C` : `${0}°C`}
                        </StyledDiv>
                      </td>
                      
                      <td>
                        <button style={{backgroundColor : colorMapper[connector?.fault_status]}} className='btn-fault-category'>{connector.fault_status}</button>
                      </td>
                      <td style={{color:"#121212"}}>
                        <StyledDiv>
                          {selectedTab === 1 ? `${connector?.time_to_failure?.toFixed(0)} days`
                          : selectedTab === 2 ? connector?.intervention_date ?connector?.intervention_date?.slice(0,10) : "none"
                          : (<div className="tooltip_icon">
                            <img src="https://ik.imagekit.io/rzlzf40okya/smarthelio/red_icn.svg" />
                             <span class="tooltiptext">{connector?.recommended_action}</span> 
                            </div>)}
                        </StyledDiv>
                      </td>
                    </tr>
                  ))
                  :
                  <tr>
                    <RiseLoader cssOverride={override} size={8} color={"#0009"}/>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          ) : (
            <tr style={{position:"relative", height:"200px"}}>
              <CircularProgress />
            </tr>
          )}
        </div>

     
      </div>

    </>
  )
}

ConnectorTable.propTypes = {
  connectors: PropTypes.arrayOf(Object).isRequired,
  mainStats: PropTypes.instanceOf(Object).isRequired,
};

export default ConnectorTable;
