import "./performanceReports.css";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet";
import StatusReport from "../../components/StatusReportForm/StatusReport";
// import connectorDevices from "../../content/connectorDevices.json";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getConnectorListResult } from "store/FaultClassificationPage/FaultClassificationReducer";


const StyledLink = styled(Link)`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  text-decoration: unset;

  &:hover {
    color: #000000;
    text-decoration: unset;
  }
`;

const DeviceInfoPage = () => {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [connectorList, setConnectorList] = useState(null)
  const params = useParams();

  const { id } = params;

  useEffect(() => {
    let connectors = JSON.parse(localStorage.getItem("connector-list"))
    if(!connectorList){
      setConnectorList(connectors)
    }
  }, [])

  useEffect(() => {
    if (id && connectorList) {
      const tempDevice = connectorList.filter(
        (item) => item.device_id.toString() === id.toString()
      );
      if (tempDevice) {
        setSelectedDevice(tempDevice[0]);
      }
    }
  }, [connectorList]);

  const getSelectionLink = () => {
    if (selectedDevice?.current_status === "faulty") {
      return "/fault-classification?faulty";
    }
    if (selectedDevice?.catcurrent_status === "hazardous") {
      return "/fault-classification?hazardous";
    }
    return "/fault-classification";
  }
  if (selectedDevice) {
    return (
      <div className="performance-row">
        <div className="performance-heading-row">
          <div>
            <div>
              <h2 className="performance-heading-h2">
                {selectedDevice?.connector_name}
              </h2>
              <p className="performance-heading-p">
                {/*<StyledLink to={"/fault-classification"}>*/}
                <StyledLink to={getSelectionLink()}>
                  Fault Classification
                </StyledLink>
                <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                <span>{selectedDevice?.device_name}</span>
              </p>
            </div>
          </div>
        </div>
        <StatusReport
          id={selectedDevice?.device_id}
          selectedDevice={selectedDevice}
        />
      </div>
    );
  }

  console.log("selected device", selectedDevice, "list", connectorList)
  return (
    <div className="performance-row">
      <Helmet>
        <meta charSet="utf-8" />
        <title>IOT Device | SmartHelio</title>
      </Helmet>
      <div className="performance_wrap">
        <div>
          <CircularProgress />
        </div>
      </div>
    </div>
  );
};

export default DeviceInfoPage;
